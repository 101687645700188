<template>
    <div style="padding-top: 8em;">
        <section class="intro clearfix header_service">
            <div class="container" style="max-width:65em; min-height:20em">
                <div class="row">
                <div class="overlay-green"></div>
                    <v-col class="col-md-8 intro-info text-left align-self-center" md="8" style="z-index: 1;">
                        <h1 class="text-uppercase bold text-white mb-4 ls-1 w-75">Pendirian PT Perorangan</h1>
                        <p class="mb-5 text-white h5">Legalpedia siap melayani Jasa Pembuatan PT Perorangan sesuai kebutuhan Anda. <br> <br>
                            PT Perorangan adalah badan hukum PT yang bisa didirikan oleh 1 orang dengan kriteria usaha mikro dan kecil
                        </p>
                    </v-col>
                    <v-col class="col-md-4" md="4" style="z-index: 1;">
                        <div class="card text-darkgreen">
                            <div class="card-header pt-5 pb-5 border-0">
                                <span>Mulai dari</span>
                                <p class="mt-2 text-center mb-0 pb-0"><s>2.500.000</s></p>
                                <h3 class="text-center mb-0">
                                    IDR 1.500.000
                                </h3>
                            </div>
                            <div class="card-body">
                                <v-btn block small color="primary" class="p-4">Dapatkan Sekarang</v-btn>
                            </div>
                        </div>
                    </v-col>
                </div>
            </div>
        </section>
        <section>
            <v-container class="mt-2">
                <v-row>
                    <v-col cols="12" class="mb-0 mt-0">
                        <v-breadcrumbs :items="breadcumbs" class="mb-1 pb-1 breadcrumb">
                            <template v-slot:item="{ item }">
                                <v-breadcrumbs-item
                                    :to="item.href"
                                    class="text-subtitle-2 crumb-item "
                                    :disabled="item.disabled"
                                    exact
                                >
                                    {{ item.text }}
                                </v-breadcrumbs-item>
                            </template>
                        </v-breadcrumbs>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-8 mb-8" id="start">
            <v-container class="container">
                <v-row align="center" justify="center">
                    <div class="col-md-4 mb-5" v-for="(detail, index) in services" :key="index">
                        <div class="card sub-service-card">
                            <div class="card-body">
                                <img :src="detail.image" class="center mb-3 img-fluid" style="height: 168px;" v-if="detail.image">
                                <img src="@/assets/pt.png" class="center mb-3 img-fluid" style="height: 168px;" v-else>
                                <a href="">
                                </a>
                                <a href="" class="text-center">
                                    <h5>{{detail.title}}</h5>
                                </a>
                                <p class="mb-1">Bonus :</p>
                                <p class="mb-0 pb-0 mt-1" v-for="(syarat, index) in detail.service_item_syarats" :key="index">
                                    <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                </p>

                                <div class="w-100" style="height:180px">
                                    <p class="mb-1">Fasilitas :</p>
                                    <p class="mb-0 pb-0 mt-1" v-for="(syarat, index) in detail.service_item_facilities" :key="index">
                                        <v-icon color="primary">{{syarat.icon.trim().substr(0,3) == 'mdi' ? syarat.icon : 'mdi-' + syarat.icon}}</v-icon> {{syarat.name}}
                                    </p>
                                </div>
                            </div>
                            <div class="card-footer border-0 d-flex jutify-content-between">
                                <div class="price-tag w-75">
                                    <span class="small"><s>{{detail.discount_price }}</s></span>
                                    <p class="bold mb-0 mt-0">IDR {{detail.price }}</p>
                                </div>
                                <div class="w-25">
                                    <a :href="detail ? detail.url_link_wa : 'https://api.whatsapp.com/send?phone=+6282120938886&text=Hi%20LegalPedia,%20Saya%20ingin%20konsultasi%20.%20Mohon%20Dibantu'" target="_blank" class="button btn btn-danger mt-1">
                                        <v-icon color="#fff" small>
                                            mdi-arrow-right
                                        </v-icon>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-row>
            </v-container>
        </section>
        <section>
            <v-container class="container">
                <div class="card card-layanan wow fadeInUp" data-wow-delay=".15s" style="visibility: visible; animation-delay: 0.15s; animation-name: fadeInUp;">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-lg-6 d-flex justify-content-center wow fadeInUp" data-wow-delay=".2s" style="visibility: visible; animation-delay: 0.2s; animation-name: fadeInUp;">
                                <img src="@/assets/difference.jpeg" alt="" style="width:400px">
                            </div>
                            <div class="col-lg-6 d-flex flex-column align-items-center align-self-center wow fadeInUp" data-wow-delay=".25s" style="visibility: visible; animation-delay: 0.25s; animation-name: fadeInUp;">
                                <h4 class="m-2 p-2 text-center text-dark">Apa bedanya Pendirian PT Perorangan dengan Pendirian PT biasa?</h4>
                                <p class="m-2 p-2 text-center text-dark">PT Perorangan hadir sebagai solusi legalitas bisnis dari pemerintah untuk usaha rintisan Anda yang bersiap maju dan tumbuh lebih besar. Berbeda dengan PT biasa, PT Perorangan hanya bisa didirikan oleh 1 orang dan tidak memerlukan akta penditian di hadapan notaris. Serta, PT Perorangan dibatasi untuk kegiatan usaha skala mikro dan kecil. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </v-container>
        </section>
        <section id="testimoni" class="pt-12 mt-12 pb-12">
            <v-container class="p-2">
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-dark mb-4 bold">
                        Keuntungan mendirikan PT Perorangan
                        </h3>
                    </v-col>
                </v-row>
                <v-row align="center" justify="center">
                    <v-col class="col-12" md="4">
                        <div class="card border-0 shadow-lg">
                            <div class="card-body" style="min-height: 296px;">
                                <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/perorangan-1.png" class="img-fluid mt-3 mb-3">
                                    </div>
                                    <div class="col-sm-8">
                                        <h5>
                                            <strong>Cukup satu orang pendiri saja</strong>
                                        </h5>
                                        <ul>
                                            <li>Melegalkan bisnis Anda menjadi lebih cepat dan fleksibel</li>
                                            <li>Tidak perlu akta pendirian</li>
                                            <br><br>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="col-12" md="4">
                        <div class="card border-0 shadow-lg">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/perorangan-2.png" class="img-fluid mt-3 mb-3">
                                    </div>
                                    <div class="col-sm-8">
                                        <h5>
                                            <strong>Kemudahan mengurus legalitas dan administrasi</strong>
                                        </h5>
                                        <ul>
                                            <li>Miliki rekening bank perusahaan</li>
                                            <li>Bisa ikut dalam tender pemerintah</li>
                                            <li>Dapatkan tambahan modal dan investor</li>
                                            <br>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="col-12" md="4">
                        <div class="card border-0 shadow-lg">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-sm-4">
                                        <img src="@/assets/perorangan-3.png" class="img-fluid mt-3 mb-3">
                                    </div>
                                    <div class="col-sm-8">
                                        <h5>
                                            <strong>Anda bisa memulai sekarang juga</strong>
                                        </h5>
                                        <ul>
                                            <li>PT Perorangan membantu Anda untuk bisa mulai melegalkan dan meningkatkan UMKM Anda sekarang juga</li>
                                            <li>Dapat ditingkatkan menjadi PT biasa</li>
                                            <br>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <section class="mt-12 mb-12">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h3 class="font-open-sans text-center text-dark mb-4 bold">
                            Mengapa Pendirian PT Perorangan Perlu Dibantu Legalpedia ?
                        </h3>
                    </v-col>
                </v-row>
                <v-row> 
                    <v-col class="col-md-4 col-12">
                        <div class="card border-0">
                            <div class="card-body rounded" style="background:#f5f6f8;min-height: 180px;">
                                <div class="row align-items-center">
                                    <div class="col-sm-12">
                                        <p class="h5">
                                        <span class="badge badge-secondary">1</span>
                                        Memesankan nama PT sesuai keinginan Anda
                                        </p>
                                        <p class="small mb-0 text-secondary">
                                            Anda akan dibantu untuk memilih dan memesan nama PT yang tidak hanya sesuai keinginan Anda, tapi juga sesuai dengan peraturan yang berlaku. 
              
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="col-md-4 col-12">
                        <div class="card border-0">
                            <div class="card-body rounded" style="background:#f5f6f8;min-height: 180px;">
                                <div class="row align-items-center">
                                    <div class="col-sm-12">
                                        <p class="h5">
                                        <span class="badge badge-secondary">2</span>
                                        Pembayaran PNBP
                                        </p>
                                        <p class="small mb-0 text-secondary">

                                        Anda tidak perlu khawatir dalam mengurus pembayaran PNBP (Penerimaan Negara Bukan Pajak) karena kami akan membantu mengurus pembayarannya. 
              
              
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                    <v-col class="col-md-4 col-12">
                        <div class="card border-0">
                            <div class="card-body rounded" style="background:#f5f6f8;min-height: 180px;">
                                <div class="row align-items-center">
                                    <div class="col-sm-12">
                                        <p class="h5">
                                        <span class="badge badge-secondary">3</span>
                                        Membantu memilihkan KBLI yang sesuai
                                        </p>
                                        <p class="small mb-0 text-secondary">

                                        Kami akan membantu Anda dalam memilih KBLI yang sesuai sehingga Anda mendapatkan perizinan berusaha yang sesuai dengan model bisnis yang Anda jalankan. 
              
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </section>
        <contact-us></contact-us>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import {service_item_api} from "@/backend-api/service_item_api"

import ContactUs from "@/components/layout_front/ContactUs.vue"
import HeaderService from "@/components/layout_front/HeaderService.vue"

export default {
    components: {
        ContactUs, HeaderService
    },
    data: () => ({
        data : '',
        panel:[0,1,2,3],
        breadcumbs: [
            {
                text: 'Home',
                disabled: false,
                href: '/',
            },
            {
                text: 'Pendirian PT Perorangan',
                disabled: true,
            }
        ],
        services: []
    }),
    methods:{
        async fetchData(){
            var respData = await service_item_api.front(`?service_id=3`, null, false, false, false)
            if (respData.status === 200) {
                this.services = respData.data.data
            } 
        },
    },
    async mounted() { 
        await this.fetchData()
    },
    watch: {
    }
}
</script>

<style scoped>

</style>